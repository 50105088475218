import { Injectable } from '@angular/core';
import { map, Observable, take } from 'rxjs';

import { GlobalLocalStorageKey } from '@stsm/shared/enums/global-localstorage-key';
import { BrowserStorageService } from '@stsm/shared/services/browser-storage/browser-storage.service';
import { LayoutStore } from '@stsm/shared/services/layout-store.service';

import { OnboardingStore } from '../../onboarding/onboarding-store.service';

@Injectable({ providedIn: 'root' })
export class MobileDisclaimerService {
  constructor(
    private readonly _browserStorageService: BrowserStorageService,
    private readonly _layoutStore: LayoutStore,
    private readonly _onboardingStore: OnboardingStore,
  ) {}

  isDisabled(): boolean {
    return Boolean(this._browserStorageService.getItemLocalStorage(GlobalLocalStorageKey.DISABLE_MOBILE_DISCLAIMER));
  }

  isEnabled(): boolean {
    return !this.isDisabled();
  }

  disable(): void {
    this._browserStorageService.setItemLocalStorage(GlobalLocalStorageKey.DISABLE_MOBILE_DISCLAIMER, true);
  }

  enable(): void {
    this._browserStorageService.removeItemLocalStorage(GlobalLocalStorageKey.DISABLE_MOBILE_DISCLAIMER);
  }

  shouldShowDisclaimer(): Observable<boolean> {
    return this._onboardingStore.isRunningUnfiltered$.pipe(
      take(1),
      map((isOnboardingRunning: boolean | undefined) => {
        return this.isEnabled() && this._layoutStore.isWebAppInPhoneLayout() && !isOnboardingRunning;
      }),
    );
  }
}
